import { createSlice } from '@reduxjs/toolkit';
import { partition, sortBy } from 'lodash';

const initialState = {
	companies: [],
	currentCompany: null,
};

const isAuthorized = (comp) => {
	if (!!comp.isAuthorized)
		return true;
	return false;
}

const orderCompanies = (data) => {
	var comps = !!data ? data : [];
	comps = sortBy(comps, ["cif", "name"]);
	const [ac, uc] = partition(comps, isAuthorized);
	comps = ac.concat(uc);

	return comps;
}

const getFirst = (companies) => {
	if (!companies || !companies.length)
		return null;
	// let availableCompanies = companies.filter(company => company.isAuthorized);
	let availableCompanies = companies;
	if (!availableCompanies.length)
		return null;
	return availableCompanies[0] ?? null;
}

export const companySlice = createSlice({
	name: 'company',
	initialState,
	reducers: {
		addCompany: (state, action) => {
			state.companies = [...state.companies, action.payload].sort((a, b) => a.name > b.name ? 1 : -1);
			if (state.currentCompany?.id === "")
				state.currentCompany = action.payload;
		},
		removeCompany: (state, action) => {
			state.companies = state.companies.filter(company => company.id !== action.payload);
			if (state.currentCompany?.id === action.payload)
				state.currentCompany = null;
		},
		updateCompany: (state, action) => {
			if (!action.payload)
				return;

			let index = state.companies.findIndex(company => company.id === action.payload.id);
			if (index > -1) {
				let left, right;

				if (index > 0)
					left = state.companies.slice(0, index);
				else
					left = [];

				if (index < state.companies.length)
					right = state.companies.slice(index + 1, state.companies.length);
				else
					right = [];

				state.companies = orderCompanies([...left, action.payload, ...right]);
				if (!!state.currentCompany && state.currentCompany.id === action.payload.id)
					state.currentCompany = action.payload;
			}
		},
		setCompanies: (state, action) => {
			state.companies = orderCompanies(action.payload);

			let company = state.currentCompany;
			if (!!company)
				company = state.companies.find(comp => comp?.id === company?.id)
			if (!company)
				company = getFirst(state.companies);
			// if(!company.isAuthorized)
			// 	company = null;

			state.currentCompany = company;
		},
		selectCompany: (state, action) => {
			if (!!action.payload)
				state.currentCompany = state.companies.find(company => company.id === action.payload) ?? null;
		},
		clear: (state) => {
			return initialState;
		}
	},
});

export const selectCompanies = (state) => state.companyReducer.companies;
export const selectCurrentCompany = (state) => state.companyReducer.currentCompany;

export const { addCompany, removeCompany, updateCompany, setCompanies, selectCompany, clearCompanyData } = companySlice.actions;

export default companySlice.reducer;

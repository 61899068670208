import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { Storage } from 'hooks/storage';
import userReducer from './userSlice';
import alertReducer from './alertSlice';
import companyReducer from './companySlice';
import subscriptionReducer from './subscriptionStatusSlice';

const storage = new Storage();
const persistentState = storage.get("redux");

export const RESET_STORE = 'user/signout';

const appReducer = combineReducers({
	userReducer,
	alertReducer,
	companyReducer,
	subscriptionReducer
})

const rootReducer = (state = {}, action) => {
	if (action.type === RESET_STORE)
		return appReducer({}, action);
	return appReducer(state, action);
};

export const store = configureStore({
	preloadedState: !persistentState ? {} : JSON.parse(persistentState),
	reducer: rootReducer,
});
import { backendClient, commonClient } from "./clients";

async function getCompanyDetails(cif, cancel = new AbortController()) {
  return commonClient
    .request({
      url: "/CompanyDetails",
      method: "post",
      signal: cancel.signal,
      data: {
        cui: cif,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
}

async function getPublicNotifications() {
  return commonClient
    .request({
      url: "/GetPublicNotifications",
      method: "post",
    })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
}

async function getUserNotifications() {
  return backendClient
    .request({
      url: "/GetUserNotifications",
      method: "post",
    })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
}
async function createtUserSubscriptionRequests(userData) {
  return backendClient
    .request({
      url: "SubscriptionRequests",
      method: "post",
      data: {
        userData,
      },
    })
    .then((res) => res.data)
    .catch((err) => {
      throw new Error(err);
    });
}

export {
  getCompanyDetails,
  getPublicNotifications,
  getUserNotifications,
  createtUserSubscriptionRequests,
};

import Cookies from 'universal-cookie';

function useCookie(name) {
    const cookies = new Cookies();

    return {
        getCookie: () => cookies.get(name),
        setCookie: (value, options = {}) => cookies.set(name, value, { ...options }),
        removeCookie: () => cookies.remove(name)
    };
}

export default useCookie;
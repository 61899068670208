import axios from "axios";
import { store } from "../reducers/store";
import { addAlert } from "../reducers/alertSlice";

export const backendClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/backend`,
  headers: {},
});

export const commonClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/common`,
  headers: {},
});

// export const reportClient = axios.create({
//   baseURL: `${process.env.REACT_APP_SERVER_URL}/reports`,
//   headers: {},
// });

export const reportClient = axios.create({
  // baseURL: `${process.env.REACT_APP_SERVER_URL}/reports`,
  baseURL: "https://efacturatest160523.azure-api.net/reports/",
  headers: {
  //   Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwiZW1haWwiOiJ0ZXN0QG1haWwuY29tIiwidGVsZXBob25lIjoiMDc0MTMyOTcxMCIsInVzZXJuYW1lIjoidGVzdEBtYWlsLmNvbSIsImhhc0F1dGhlbnRpY2F0b3IiOmZhbHNlLCJpZCI6ImQ2NTkzYWRjLWU1NDItNTkxNy1iNDYzLTk4MjYzMGMyOGY0MCIsInN1YnNjcmlwdGlvbktleXMiOnsiYmFja2VuZCI6ImFiYyIsImVmYWN0dXJhIjoiZGVmIiwicmVwb3J0IjoiZ2hpIn0sInNjb3BlIjpbImJhY2tlbmQiLCJlZmFjdHVyYSIsInJlcG9ydCJdLCJpYXQiOjE2OTExMjk3MjMsImV4cCI6MTY5MzcyMTcyM30.i97P5LHh_fsCRi6A74QJUAabQoLWypnBonQw7PfgwBg`,
  },
});

let isServerErrorNotified = false;

const notifyServerError = () => {
  if (!isServerErrorNotified) {
    store.dispatch(
      addAlert({
        type: "error",
        title: "Eroare de Server",
        message:
          "Serverul este momentan indisponibil. Vă rugăm să încercați din nou mai târziu.",
        delay: 3,
      })
    );
    isServerErrorNotified = true;

    setTimeout(() => {
      isServerErrorNotified = false;
    }, 2000);
  }
};

const addInterceptorToClient = (client) => {
  client.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 500) {
        notifyServerError();
      }
      return Promise.reject(error);
    }
  );
};

export const setupAxiosInterceptors = () => {
  isServerErrorNotified = false;

  [backendClient, commonClient, reportClient].forEach(addInterceptorToClient);
};
